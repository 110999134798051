@import "~bootstrap/scss/bootstrap";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";
@import "animation";

.ant-skeleton.ant-skeleton-element {
  width: 100%;
}
.ant-skeleton-image {
  width: 100% !important;
}

@media (min-width: 320px) and (max-width: 668px) {
  .col-sm-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
}

.related-product-card {
  margin-bottom: 8px;
  border: 1px solid lightgray !important;
  background: white;
  width: 100%;
  z-index: unset;
  max-width: 400px !important;
  border-radius: var(--border-radius);
}

.product-container {
  display: flex;
  width: 100%;
}

.product-checkbox {
  height: 15px !important;
  width: 15px !important;
  padding: 0px !important;
  margin-left: 10px;
}

.product-body {
  align-items: center;
  gap: 10px;
  display: flex;
  width: 100%;
}

.product-image-container {
  margin: 9px 0 9px 4px;
  height: 75px;
  width: 75px;
}

.product-image {
  border-radius: var(--border-radius);
  height: 75px;
  width: auto;
}

.product-details {
  margin: 16px;
  width: 100%;
}

.product-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
  color: var(--primary-text-color);
  line-height: 21px !important;
}

.product-price {
  margin-top: 0;
}

.sale-price {
  color: var(--sale-price-color, #c8493f);
}

.compare-price {
  font-size: 12px;
  margin-left: 4px;
  text-decoration: line-through;
  color: var(--compare-price-color);
}

.product-variants-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.variants-select {
  width: 135px;
  padding-right: 20px !important;
  background-image: unset !important;
  min-height: 32px;
  border-color: #d3d3d3 !important;
  outline: 0 !important;
  box-shadow: none;
  margin: 0 !important;
}

.related-product-title {
  font-weight: 500;
  font-size: min(1.3em, 24px);
  margin-bottom: 5px;
  padding: 0px;
  text-align: left;
  width: 100%;
}

.fwm {
  font-weight: 500;
}

.cd {
  color: #222;
}
.theme-btn {
  font-weight: bold;
  line-height: 1;
  z-index: 99;
  display: inline-block;
  padding: 23px 38px 23px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  background: none;
  background-color: #343538;
}
.theme-btn::before {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.theme-btn::after {
  right: 0;
  left: auto;
  width: 0;
  background: #fa4eab !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .theme-btn {
    padding: 22px 22px 22px;
  }
}
.review-content {
  width: 100%;
}
.primary {
  background-color: $primary;
}
.secondary {
  background-color: $secondary;
}
/* General Toast Styles */
.minimal-toast {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 12px;
  max-width: 300px; /* Fixed width to ensure it doesn't exceed 300px */
  width: 100%;
  transition: all 0.3s ease;
  word-wrap: break-word;
  margin: 0 auto; /* Center it on the screen */
}

/* Toast Avatar */
.toast-avatar-div {
  flex-shrink: 0;
}

.toast-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid pink;
}

/* Toast Content */
.toast-content {
  margin-left: 10px;
  flex-grow: 1;
  max-width: 230px; /* Fixed width for text content to prevent overflow */
  min-width: 0;
  text-align: left;
}

.toast-title {
  font-size: 0.8rem; /* Reduced font size */
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Ensures long text is truncated with ellipsis */
}

.toast-location {
  font-size: 0.7rem; /* Smaller font for location */
  color: #666;
  margin-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Ensures long text is truncated with ellipsis */
}

/* Specific Mobile Design */
@media (max-width: 320px) {
  .minimal-toast {
    max-width: 100%; /* Ensure it fits within the 320px screen */
    padding: 10px;
    border-radius: 8px; /* Slightly smaller border radius for compact look */
  }

  .toast-avatar {
    width: 30px; /* Smaller avatar size */
    height: 30px;
  }

  .toast-content {
    margin-left: 8px;
    max-width: 200px; /* Reduced width for content to fit on smaller screens */
  }

  .toast-title {
    font-size: 0.7rem; /* Slightly reduced font size */
  }

  .toast-location {
    font-size: 0.6rem; /* Slightly reduced font size */
  }
}

/* Toast Info for Appearance */
.ct-toast-info {
  border-left: 4px solid pink !important;
  padding: 12px 10px !important;
}

.ct-toast-info svg path {
  fill: pink !important;
}
