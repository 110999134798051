@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.maintenance-container {
  position: relative;
  height: 100vh;
  background-color: #fbd3e9;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}

.animated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #fbd3e9, #fff);
  background-size: 200% 200%;
  animation: bg-pan 4s infinite linear;
  z-index: 0;
}

@keyframes bg-pan {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content {
  position: relative;
  z-index: 1;
  color: #333;
  padding: 0 20px; /* Adding padding to ensure the text doesn't touch the sides */
}

.logo {
  width: 250px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #e1306c;
}

p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Media queries for mobile adjustments */
@media (max-width: 600px) {
  .logo {
    width: 180px; /* Smaller logo on mobile */
  }

  p {
    padding: 0 20px; /* Ensure text has padding on mobile */
  }
}
